<template>
  <div v-if="section?.fields.fullWidth" :class="$style['full-width-section']">
    <MappedComponents :items="items" />
  </div>
  <ContentSection
    v-else
    :id="section?.fields.id ?? `section-${items[0]?.sys.id}`"
    :class="classes"
    :color-mode="section?.fields.theme ?? 'light'"
  >
    <MappedComponents :items="items" />
  </ContentSection>
</template>

<script setup lang="ts">
import { ContentSection } from '@hypercodestudio/basler-components';
import type { Entry } from 'contentful';
import type { ISection, ITechnicalComponent } from '~/lib/ContentfulService';

interface Props {
  section: ISection | undefined;
  items: Entry<unknown>[];
}

const props = defineProps<Props>();

const classes = computed(() => ({
  // XXX: there might be a more elegant solution to this.
  // @see https://gcp.baslerweb.com/jira/browse/DBP-930
  'content-section--color-transparent': props.items?.some((item) => {
    if (item?.sys?.contentType?.sys?.id === 'technicalComponent') {
      return (
        (item as ITechnicalComponent).fields?.type === 'documentCenter' ||
        (item as ITechnicalComponent).fields?.type === 'softwareCenter'
      );
    }

    return false;
  })
}));
</script>

<style module>
.full-width-section {
  background-color: #fff;
}
</style>
